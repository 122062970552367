<template>
  <div :class="{content: true, '-icons': isIconLayout }">
    <transition name="fade">
      <Konami :konami="brand.konami" v-if="code" key="konami"></Konami>
      <CmpRenderer :components="brand.content" v-else key="content"></CmpRenderer>
    </transition>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { BrandComponent } from 'src/modules/prismic/components/BrandComponent'
import Konami from '../components/utils/Konami'
import config from 'config'

export default {
  name: 'Brand',
  components: {
    Konami
  },
  mixins: [BrandComponent],
  asyncData({ context }) {
    return new Promise((resolve, reject) => {
      if (context) context.output.cacheTags.add(`brand`)
      return resolve()
    })
  },
  computed: {
    isIconLayout() {
      return this.brand && this.brand.main && this.brand.main.layout
    },
    localizedUrl() {
      return currentStoreView().url
    },
    clientUrl() {
      return config.client.url
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

// Fade
.fade-enter,
.fade-leave-to {
  left: 0;
  right: 0;
}

header {
  margin: 0 auto 5rem;
  max-width: 1200px;

  .h3 {
    margin-bottom: 3rem;
    font-size: em(44);
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: em(0.2, 18);
  }

  .date {
    font-weight: 500;
    color: var(--zenith-grey);
    margin-bottom: 2rem;
    vertical-align: text-bottom;
    i {
      vertical-align: text-bottom;
      display: inline-block;
      margin-right: 0.4rem;
    }
  }
  .wysiwyg {
    font-size: em(26);
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: em(0.2, 18);

    @include bp(max md) {
      font-size: em(16);
      letter-spacing: em(1, 16);
    }
  }
  .visual {
    width: 100%;
    display: block;
    margin-bottom: 4rem;
  }
}

.overlay {
  transform: translateY(-34%);
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
}

.content {
  .wysiwyg,
  h2 {
    margin-bottom: 4rem;
    font-size: em(20);
    line-height: 1.8;
    letter-spacing: em(0.2, 18);
  }

  h2 {
    font-weight: 500;
    font-size: em(26);
    margin-bottom: 2rem;
  }

  section {
    text-align: center;
    margin: 0 auto 4rem;

    &.embed-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      /deep/ iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

aside {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    @include bp(max md) {
      text-align: left;
    }

    li {
      margin-bottom: 2rem;

      @include bp(max md) {
        display: inline-block;
        margin-right: 4rem;
      }

      a {
        color: var(--zenith-theme-primary);
        position: relative;
        display: block;
        transition: all 0.2s ease-in-out;

        i {
          font-size: em(30);
        }

        &:focus,
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.news-nav {
  display: flex;
  justify-content: space-between;

  a {
    color: var(--zenith-theme-primary);
    width: 40%;

    @include bp(max md) {
      width: 50%;
    }

    i {
      display: inline-block;
      margin-right: 0.4rem;
      vertical-align: text-bottom;
      transition: all 0.2s ease-in-out;
    }

    span {
      transition: all 0.2s ease-in-out;

      &:last-child {
        color: var(--zenith-grey);
        display: block;
        margin-top: 0.8rem;

        @include bp(max md) {
          display: none;
        }
      }
    }

    &:last-child {
      text-align: right;

      i {
        margin-right: 0;
        margin-left: 0.4rem;
      }
    }

    &:hover,
    &:focus {
      i {
        margin-right: 1rem;
      }
      span {
        color: var(--zenith-theme-primary);
      }
      &:last-child {
        i {
          margin-right: 0;
          margin-left: 1rem;
        }
      }
    }
  }
}

.related_product {
  background: #f8f8f8;
  padding: 6rem 0 14rem;
  @include bp(max md) {
    padding: 5rem 0 10rem;
  }

  .product {
    width: 50%;
    display: flex;
    margin: auto;
    align-items: center;

    @include bp(max lg) {
      width: 80%;
    }
    @include bp(max md) {
      width: 100%;
    }

    .detail {
      margin-left: 2rem;
    }

    .sku {
      color: var(--zenith-grey);
      margin-bottom: 0.5rem;
      font-style: em(12);
      letter-spacing: em(0.2, 12);
    }

    .h6 {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      font-style: em(12);
      letter-spacing: em(0.2, 12);
      margin-bottom: 2rem;
    }

    @include bp(max md) {
      .btn.-large {
        width: 100%;
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }
  }
}
</style>
